const UNITS_BY_DEFAULT = ['k', 'm', 'b', 't']

function _abbreviate(
  number: number,
  decPlaces: number,
  units = UNITS_BY_DEFAULT
) {
  decPlaces = Math.pow(10, decPlaces)

  let result = String(number)

  for (let i = units.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3)

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces

      if (number === 1000 && i < units.length - 1) {
        number = 1
        i++
      }

      result = number + units[i]

      break
    }
  }

  return result
}

function abbreviate(number: number, decPlaces?: number) {
  const isNegative = number < 0
  const abbreviatedNumber = _abbreviate(Math.abs(number), decPlaces ?? 0)

  return isNegative ? '-' + abbreviatedNumber : abbreviatedNumber
}

export default function (value = 0) {
  return abbreviate(value, 1)
}
